import './_app.scss';

import { useEffect, useMemo, useState } from 'react';
import { AtomicStateProvider } from '@immobiliarelabs/atomic-state';
import { AuthProvider } from '@indomita-react/auth-provider';
import {
  getCommonTrackingData,
  setCommonTrackingData,
} from '@indomita-react/segment';
import { ESTATS_TYPE, sendMetricRequest } from '@pepita/estats';
import pepitaSprite from '@pepita-fe/sprite-pepita/sprite.svg';
import { TranslationsProvider } from '@pepita-react/i18n';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import type { NextWebVitalsMetric } from 'next/dist/shared/lib/utils';
import { Router, useRouter } from 'next/router';

import { AuthModalDialog } from 'src/components/AuthModal';
import { BotDetection } from 'src/components/BotDetection';
import { BrazeTracker } from 'src/components/BrazeTracker';
import { CookieManager, CookieProvider } from 'src/components/CookieManager';
import { GoogleOneTap } from 'src/components/GoogleOneTap';
import { JotaiProvider } from 'src/components/JotaiProvider';
import ProductHead from 'src/components/ProductHead';
import { UserProvider } from 'src/components/UserProvider';
import { WebFont } from 'src/components/WebFont';
import { EnvFeatureToggleProvider } from '../components/EnvFeatureToggleProvider';
import { SEOTags } from '../components/SEOTags';

import { getProductConfig } from 'src/config/product';

import { useWhitelistParams } from 'src/hooks/useWhitelistParams';

import { GPTProvider } from 'src/libs/google-publisher-tag';
import type { Context } from 'src/libs/growthbook/client';
import {
  createGrowthBookInstance,
  GrowthBookProvider,
} from 'src/libs/growthbook/client';
import { PepitaDialogProvider } from 'src/libs/ui/pepita-dialog';
import { usePepitaInlineSvgSprite } from 'src/libs/ui/pepita-icon';
import { PepitaLeafletMapProvider } from 'src/libs/ui/pepita-leaflet-map';
import { PepitaSnackbarProvider } from 'src/libs/ui/pepita-snackbar';

import { useWebVitals } from 'src/tracking/ga/hooks/useWebVitals';
import { SegmentAnalyticsProvider } from 'src/tracking/segment';

import type { RegisterType } from 'src/types/auth';
import type { SEOTagsType } from 'src/types/seo';

import { estatsMetrics } from 'src/utils/estats';
import type { InitialProps } from 'src/utils/getServerSideProps';
import { deserializeFromUrl, serializeIntoUrl } from '../utils/querystring';

/**
 * ☠️ BEWARE ☠️
 *
 * This variable could be trusted only on the Client side because in there we manage one user at time
 */
let vitalsSectionName: string;

// Levels extracted from https://web.dev/cls/
function getStatusName(metric: NextWebVitalsMetric) {
  if (metric.name === 'CLS') {
    if (metric.value >= 0.25) {
      return 'poor';
    }

    if (metric.value >= 0.1) {
      return 'needs_improvement';
    }

    return 'good';
  }

  if (metric.name === 'LCP') {
    if (metric.value >= 4000) {
      return 'poor';
    }

    if (metric.value >= 2500) {
      return 'needs_improvement';
    }

    return 'good';
  }

  if (metric.name === 'INP') {
    if (metric.value > 500) {
      return 'poor';
    }

    if (metric.value > 200) {
      return 'needs_improvement';
    }

    return 'good';
  }

  return false;
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === 'web-vital' && vitalsSectionName) {
    const deviceSize = matchMedia('(max-width: 768px)').matches
      ? 'mobile'
      : 'desktop';

    const status = getStatusName(metric);
    const name = metric.name.toLowerCase();

    if (status) {
      const statusUrl =
        estatsMetrics[
          `rum.${name}_status.${vitalsSectionName}.${deviceSize}.${status}`
        ];

      statusUrl && sendMetricRequest(statusUrl, ESTATS_TYPE.nCounter, 1);
    }

    if (name === 'lcp') {
      const value = Math.floor(metric.value);
      const deviceMetricURL =
        estatsMetrics[`rum.${name}.${vitalsSectionName}.${deviceSize}`];

      deviceMetricURL &&
        sendMetricRequest(deviceMetricURL, ESTATS_TYPE.timing, value);
    }
  }
}

const domainName = getProductConfig('domainName');
const assetsConfig = getProductConfig('assets');

type CustomAppProps = {
  trackersSectionName?: string;
  translations?: InitialProps['translations'];
  atoms?: Record<string, unknown>;
  auth?: InitialProps['auth'];
  features?: InitialProps['features'];
  registerType?: RegisterType;
  jotaiAtoms?: {
    seoData: SEOTagsType;
  };
  growthbookContext?: Context;
} & Record<string, unknown>;

function MyApp({ Component, pageProps }: AppProps<CustomAppProps>) {
  useWebVitals();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            gcTime: 1000 * 60 * 10,
          },
        },
      })
  );

  if (pageProps.trackersSectionName) {
    vitalsSectionName = pageProps.trackersSectionName;
  }

  /**
   * This hack is done in order to reuse the old Pepita's CustomElements
   */
  useMemo(() => {
    if (typeof window === 'undefined') return;
    if (!pageProps.translations) return;

    const babelfish = require('@pepita-i18n/babelfish');
    const source = pageProps.translations.value;
    const translations = {};

    for (const key in source) {
      translations[key] = source[key].join('|');
    }

    babelfish.load(translations);
  }, [pageProps.translations]);

  const atomsState = useMemo(() => pageProps.atoms, [pageProps.atoms]);

  const growthbook = useMemo(() => {
    return createGrowthBookInstance({
      ...pageProps.growthbookContext,
      trackingCallback: (experiment, result) => {
        const { 'Experimental Flags': existingFlags = [] } =
          getCommonTrackingData();

        setCommonTrackingData({
          'Experimental Flags': Array.from(
            new Set(existingFlags).add(`${experiment.key}-${result.key}`)
          ),
        });
      },
    });
  }, [pageProps.growthbookContext]);

  usePepitaInlineSvgSprite(pepitaSprite);

  const router = useRouter();

  // TODO The parameters could be extracted inside the effect without using an hook
  const queryWhitelistedParams = useWhitelistParams();

  // By default we remove every unrecognized parameter from the querystring on page load by triggering a route change
  // but there are some parameters (most of them are for marketing) that should not be removed and have to be re-added after every navigation
  // So we read the "whitelisted" parameters from the querstring during page load and re-add them at every route change
  useEffect(() => {
    const handleRouteChange = (url, options) => {
      const queryParams = deserializeFromUrl(url);
      const paramsKeys = Object.keys(queryParams);

      // Checking if we have all whitelist params in the url
      if (
        !Object.keys(queryWhitelistedParams).every((whitelistParam) =>
          paramsKeys.includes(whitelistParam)
        )
      ) {
        // Stopping old navigation
        Router.events.emit(
          'routeChangeError',
          new Error('missing whitelist params')
        );
        // Redirecting to old url with the whitelist params
        router.replace(
          serializeIntoUrl(url.split('?')[0], {
            ...queryParams,
            ...queryWhitelistedParams,
          }),
          undefined,
          options
        );
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <>
      <ProductHead domain={domainName} assets={assetsConfig} />
      <EnvFeatureToggleProvider initialFeatures={pageProps.features || {}}>
        <GrowthBookProvider growthbook={growthbook}>
          <PepitaLeafletMapProvider>
            <JotaiProvider seoData={pageProps.jotaiAtoms?.seoData}>
              <AtomicStateProvider state={atomsState}>
                <BotDetection />

                <QueryClientProvider client={queryClient}>
                  {process.env.NEXT_PUBLIC_RQ_DEVTOOLS && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                  <AuthProvider
                    enableAdditionalData
                    user={pageProps.auth?.user ?? null}
                  >
                    <UserProvider>
                      <HydrationBoundary state={pageProps.dehydratedState}>
                        <TranslationsProvider
                          translations={
                            pageProps.translations
                              ? pageProps.translations.value
                              : {}
                          }
                          lang={
                            pageProps.translations
                              ? pageProps.translations.lang
                              : ''
                          }
                        >
                          <PepitaSnackbarProvider>
                            <WebFont />
                            <SEOTags />
                            <BrazeTracker
                              registerType={pageProps.registerType}
                            />
                            <CookieProvider>
                              <SegmentAnalyticsProvider>
                                <PepitaDialogProvider>
                                  <CookieManager />
                                  <GoogleOneTap />
                                  <AuthModalDialog />
                                  <GPTProvider>
                                    <Component {...pageProps} />
                                  </GPTProvider>
                                </PepitaDialogProvider>
                              </SegmentAnalyticsProvider>
                            </CookieProvider>
                          </PepitaSnackbarProvider>
                        </TranslationsProvider>
                      </HydrationBoundary>
                    </UserProvider>
                  </AuthProvider>
                </QueryClientProvider>
              </AtomicStateProvider>
            </JotaiProvider>
          </PepitaLeafletMapProvider>
        </GrowthBookProvider>
      </EnvFeatureToggleProvider>
    </>
  );
}

export default MyApp;
