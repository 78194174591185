import type { FeaturesToggles } from 'src/config/features-toggle';

import { isProd } from 'src/utils/environment';

const config: FeaturesToggles = {
  SIGN_IN_RATE_LIMIT: true,
  CONTACT_FORM_AGENT_PHONE_PRIORITY_OVER_AGENCY: false,
  AUCTIONS_ENABLED: true,
  VIRTUAL_TOUR_FILTER: true,
  COMMERCIAL_ACTIVITY_TYPE_FILTER: true,
  SOCIALS_ENABLED: true,
  EVALUATE_BUILDING: true,
  BEDROOM_SEARCH_ENABLED: false,
  ONLY_COMPLETE_BEDROOM_SEARCH_ENABLED: false,
  COMMERCIAL_SEARCH_AS_CATEGORIES: false,
  LABORATORY_SEARCH_ENABLED: true,
  MOBILE_UPSELLING: true,
  POI_ENABLED: true,
  LISTING_FIRST_MOSAIC_IMAGE_WATERMARK: true,
  BRAZE: true,
  BRAZE_DESKTOP_ANON_USERS: !isProd,
  REAL_ESTATE_3D_MAP_ENABLED: true,
  SELLER_LEAD: true,
  PUBLISH_MOBILE_BTN: true,
  DOSSIERS_ENABLED: false,
  ASSOCIATED_SERVICES: false,
  SEARCH_MODAL_DISABLE_FILTER_BY_PROPERTY_TYPE: false,
  GENERIC_IMAGE: false,
  PREFERRED_LOCALE_REDIRECT: false,
  SEARCH_LIST_SHOW_PROJECT_COMMERCIAL_SURFACE: true,
  ENABLE_ALTERNATE_AGENCY_SIGNUP: false,
  VISIT_REQUEST_ENABLED: true,
  VISIT_REQUEST_MULTIPLE_CHOICE_DAYS: true,
  VISIT_REQUEST_MULTIPLE_CHOICE_TIMETABLES: true,
  DOWNLOAD_PROMOTIONAL_BANNER: true,
  COUNTRY_SEARCH_SHORTCUT: false,
  DISCOVER_TITLE: false,
  MAIN_PARTNER_HOME: false,
  AGENCIES_EXPERT_COVER: true,
  AGENCIES_EXPERT_ZONE_TITLE_ENABLED: true,
  AGENCIES_EXPERT_SCARSITY_ENABLED: true,
  LISTINGS_METRO_SEARCH: true,
  REGION_SEARCH_ENABLED: true,
  COUNTRY_SEARCH_ENABLED: true,
  LISTINGS_DISTANCE_SEARCH: true,
  INTERNATIONAL_SEARCH_ENABLED: true,
  INTERNATIONAL_SEARCH_ASK_TO_AGENCIES_ENABLED: false,
  INTERNATIONAL_SEARCH_ONLY_RESIDENTIAL: true,
  INTERNATIONAL_SEARCH_DISABLE_AUCTIONS: true,
  INTERNATIONAL_SEARCH_DISABLE_AGENCIES: true,
  INTERNATIONAL_SEARCH_REMOVE_HEADER_LINK: true,
  INTERNATIONAL_HIDE_MORTGAGE: true,
  INTERNATIONAL_LABELS_ENABLED: true,
  SHOW_MORTGAGE_WIDGET: true,
  SHOW_IMMOTOP_MORTGAGE_WIDGET: false,
  MORTGAGE_AVAILABLE: true,
  LISTING_SHOW_SURFACES_CONSTITUTION: true,
  LALUX_ASSURANCE_ENABLED: false,
  SHOW_HEADER_PUBLISH_BTN: true,
  SHOW_SEARCH_LIST_PRICE_CHART: true,
  AUTH_POST_CONTACT: true,
  AUTH_POST_SAVE_SEARCH: true,
  REALESTATE_MODAL_NOT_LOGGED: true,
  PUBLISHADSPLACEHOLDER: true,
  REAL_ESTATE_PROJECTS_REDIRECT: true,
  MESSAGING_IN_LAST_SEARCH: false,
  MESSAGING_IN_LAST_THINGS_ENABLED: true,
  REALTIME_NOTIFICATIONS_ENABLED: true,
  REAL_ESTATE_SURROUNDINGS_SECTION: false,
  ENABLE_AUTH_CSRF_TOKEN: false,
  DETAIL_CLIENT_TRACK_VISIT_ENABLED: false,
  CITY_GUIDE_EVALUATION_HREF_OVERRIDE_ENABLED: true,
  AGENCIES_HOMEPAGE_SEARCH_LOCALITY_DISABLED: false,
  ENABLE_MORTGAGE_ALTERNATIVE_URL: false,
  AGENCY_URL_KEYURL: true,
  NUMBER_ONE_LINK: true,
  TRANSLATE_GA4_PROPS: true,
  ENABLE_UNIQUE_AUTH_MODAL: !isProd,
  PREVENT_BOT_TRACKING: false,
  SHOW_OUTER_ZONE_AGENCY_CAROUSEL: false,
  SEGMENT_TRACKING: true,
  SEGMENT_BATCHING: false,
  FORCE_INTERNATIONAL_PHONE_NUMBER: false,
  AGENCY_LIST_NATION_SEARCH: false,
  AGENCY_LIST_ADV: true,
  SHOW_MULTI_CONTACT_FORM: false,
  AGENCY_LIST_SEARCH_AGENCIES: false,
  ENABLE_FRANCHISING_PAGE: true,
  ENABLE_AGENTS_PAGE: !isProd,
  SEARCH_LIST_BOTTOM_SERVICES: false,
  AGENCIES_EXPERT_HOME: true,
  AGENCIES_EXPERT_SEARCH_LIST: true,
  AGENCIES_EXPERT_SORT_BY_BUNDLE: true,
  AGENCIES_EXPERT_SEARCH_LIST_PROVINCE: false,
  AGENCIES_EXPERT_SEARCH_LIST_MAP: true,
  MACROAREAS_SEARCHES: false,
  SEARCH_AGENCIES_COUNTRY: false,
  AGENCIES_EXPERT_MARKERS_SORT_BY_BUNDLE: true,
  ACTIVE_SEARCH: true,
  ACTIVE_SEARCH_FULL_FILTERS: false,
  SNOWFLAKES: false,
  SEARCH_ON_MAP_ISOCHRONOUS: !isProd,
  FORCE_AGENCY_LOGO_ON_SEARCH_LIST: isProd,
  FIX_ALTERNATE_POLYGONS: false,
  AGENCY_NAME_SEARCH: true,
  TROVAKASA_API: true,
  REAL_ESTATE_PROPERTIES_IN_COST_AND_ENERGY: true,
  REAL_ESTATE_ENERGY_CLASS_COLOR_CUSTOM: false,
  LINK_AGENCY_LOGO_ON_SEARCH_LIST: !isProd,
  GARDEN_FILTER_ON_FILTER_BAR: true,
  CONTACTED_LISTING_INFO: true,
  NEW_INDOMIOGR_REDIDENTIAL_LISTS: false,
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_LIST: true,
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_INFO: true,
  REACT_GEOGRAPHY_SEARCH: false,
  REACT_GEOGRAPHY_SEARCH_ALPHA_ALERT: false,
  LISTING_SEARCH_NEW_SORTING: true,
  LISTING_SEARCH_NEW_SORTING_OPTIONS: !isProd,
  GROWTHBOOK: true,
  GROWTHBOOK_PROXY_TEST: false,
  MY_HOME: true,
  SEARCH_TAB_MY_HOME: true,
  SEGMENT_TRACKING_PARTNERS_COOKIE: true,
  FULLSTORY: true,
  LISTING_SECTIONS_VIEWED: true,
  LISTING_DETAIL_MAP_MEDIA_TAB: false,
  AGENCIES_EXPERT_BY_DISTANCE_OR_AREA_SEARCH: true,
  LISTING_DETAIL_NEW_PARTNER_BANNER: !isProd,
  NEW_LOGO: false,
  TALL_LOGO: false,
  PRICE_PROPOSAL: !isProd,
  SAVED_LISTINGS_AVAILABILITY_FILTER: true,
  PAYOFF_CUSTOM_FONT_IMMOTOP: false,
};

export default config;
