import { useEffect } from 'react';
import { useUserCompleteData } from '@indomita-react/auth-provider';
import { brazeInitialize } from '@pepita-fe/braze';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import type { RegisterType } from 'src/types/auth';

/**
 *  Using a fake component in order to be able to use the
 * QueryClientProvider without having to use this hook
 * inside all the pages
 * @param {any} [userType] - This is the type of password the user is using. This is used to track the
 * user's password in Landing Page only
 * @returns null
 */

interface BrazeTrackerProps {
  registerType: RegisterType | undefined;
}

export function BrazeTracker({ registerType }: BrazeTrackerProps) {
  const userData = useUserCompleteData();
  const [minScreenWidthLg] = useBreakpoint({ from: 'lg' });
  const isLogged = Boolean(userData);
  const isLoggedOrDesktop = isLogged || minScreenWidthLg; // DEV-13521

  const shouldBeEnabled = isFeatureEnabled('BRAZE_DESKTOP_ANON_USERS')
    ? isLoggedOrDesktop
    : isLogged;

  useEffect(() => {
    brazeInitialize(
      userData ? { ...userData, typeOfPassword: registerType } : null,
      getProductConfig('brazeConfig'),
      isFeatureEnabled('BRAZE') && shouldBeEnabled
    );
  }, [registerType, userData, shouldBeEnabled]);

  return null;
}
