const {
  SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  NEXT_PUBLIC_SENTRY_SAMPLE_RATE,
} = process.env;

import { loadGTM } from '@pepita-fe/gtm';
import {
  getLargestLayoutShiftSource,
  trackLayoutShift,
} from '@pepita-fe/layout-shift-tracker';
import * as Sentry from '@sentry/nextjs';

import { getProductConfig } from 'src/config/product';

import { isProd } from 'src/utils/environment';

loadGTM(getProductConfig('gtm'));

const ClientError = Object.freeze({
  UNDEFINED_PROPS: 'Cannot read properties of undefined',
  NULL_PROPS: 'Cannot read properties of null',
  NON_ERROR_EXCEPTION: 'Non-Error exception',
});

let dsn = SENTRY_DSN || NEXT_PUBLIC_SENTRY_DSN;
const environment = NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SAMPLE_RATE = NEXT_PUBLIC_SENTRY_SAMPLE_RATE;

// A temporary hack to avoid having the DSN set to the string
// 'undefined' and cause an init error in the SDK. There is an internal
// var override in our pepita build plugins:
// * https://gitlab.pepita.io/pepita/pepita-frontend/next-build-plugins/blob/72976b239abd019e670bcd01ad01e9af7e26f8fd/packages/build-plugins/src/lib/sentry.ts#L8
if (dsn === 'undefined') {
  dsn = undefined;
  delete process.env.SENTRY_DSN;
  delete process.env.NEXT_PUBLIC_SENTRY_DSN;
}

function getSampleRate() {
  let v = parseFloat(SAMPLE_RATE);

  if (v >= 0 || v <= 1.0) {
    return v;
  }

  return 1.0;
}

const sampleRate = getSampleRate();

// We can use this hook to filter events we want to send.
// See https://docs.sentry.io/platforms/javascript/guides/wasm/configuration/filtering/
const beforeSend = (event, hint) => {
  /**
   * Every server error will send the client page in 505 fallback
   * whith an error like: Non-Error exception captured with keys: message, name, statusCode
   * in this way we are going to filter all this kind of errors
   */
  if (
    event.exception?.values?.every(
      (err) => err.value?.includes(ClientError.NON_ERROR_EXCEPTION)
    )
  ) {
    return null;
  }

  // Filtering unuseful errors from http client
  if (event.fingerprint?.includes('HttpClientIgnoreSentry')) {
    return null;
  }

  if (hint && hint.originalException) {
    const { name } = hint.originalException;

    //Regroups the ChunkLoadErrors to reduce noise on the dashboard
    if (name === 'ChunkLoadError') {
      event.fingerprint = ['ChunkLoadError'];
    }
  }

  if (event.exception?.values && event.exception.values.length) {
    const values = event.exception.values.map((exp) => {
      if (exp.value?.includes(ClientError.UNDEFINED_PROPS)) {
        exp.type = 'Undefined Property';
      } else if (exp.value?.includes(ClientError.NULL_PROPS)) {
        exp.type = 'Null Property';
      }

      return exp;
    });

    event.exception.values = values;
  }

  return event;
};

Sentry.init({
  dsn,
  environment,
  sampleRate,
  beforeSend,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

if (!isProd) {
  trackLayoutShift((entry) => {
    // eslint-disable-next-line no-console
    console.error(
      '[LayoutShift]',
      entry,
      getLargestLayoutShiftSource(entry.sources)?.node
    );
  });
}
