import { isFeatureEnabled } from 'src/config/features-toggle';

import type {
  ListingsSortingOption,
  SortingCriterion,
  SortingDirection,
} from '../types/sorting';

import { ensure } from 'src/utils/common';
import type { Trans } from 'src/utils/i18nFormatters';

const isListingSearchNewSortingEnabled = isFeatureEnabled(
  'LISTING_SEARCH_NEW_SORTING'
);

export const SORTING_CRITERION = {
  automatic: 'automatico',
  relevance: 'rilevanza',
  price: 'prezzo',
  surface: 'superficie',
  rooms: 'locali',
  modificationDate: 'dataModifica',
  creationDate: 'data',
  floor: 'floor',
  priceMq: 'priceMq',
  agencyAdvertisersFirst: 'agencyFirst',
  privateAdvertisersFirst: 'privateFirst',
  discounted: 'discounted',
} as const;

export const SORTING_VALUES = {
  automatic: 'automatic',
  moreRelevant: 'more-relevant',
  moreExpensive: 'more-expensive',
  larger: 'larger',
  moreLocal: 'more-local',
  latest: 'latest',
  lessExpensive: 'less-expensive',
  lessBig: 'less-big',
  lessLocal: 'less-local',
  older: 'older',
  higherFloor: 'higher-floor',
  lowestFloor: 'lowest-floor',
  moreExpensiveMq: 'more-expensive-mq',
  lessExpensiveMq: 'less-expensive-mq',
  agencyAdvertisersFirst: 'agency-first',
  privateAdvertisersFirst: 'private-first',
  discounted: 'discounted',
} as const;

const handleSortingProp = (
  sortingProp: string | string[] | undefined | null
) => {
  if (typeof sortingProp === 'string') return sortingProp;
  if (Array.isArray(sortingProp)) return sortingProp.at(0);

  return undefined;
};

export const getSelectedOption = (
  options: ListingsSortingOption[],
  sortingCriterion: SortingCriterion | string | string[] | undefined | null,
  sortingDirection: SortingDirection | string | string[] | undefined | null,
  defaultOptionValue: ListingsSortingOption['value']
) => {
  const criterion = handleSortingProp(sortingCriterion);
  const direction = handleSortingProp(sortingDirection);

  const selectedConfigIndex = options.findIndex(
    (opt) => opt.criterion === criterion && opt.direction === direction
  );

  if (selectedConfigIndex === -1) {
    return ensure(options.find((c) => c.value === defaultOptionValue));
  }

  return options[selectedConfigIndex];
};

const isSortCriterion = (
  sortingValue: string
): sortingValue is SortingCriterion =>
  (Object.values(SORTING_CRITERION) as readonly string[]).includes(
    sortingValue
  );

const DEFAULT_SORT_BY = isListingSearchNewSortingEnabled
  ? 'rilevanza'
  : 'automatico';

/**
 * Get the sort criterion from the query string.
 */
export const getSortCriterion = (
  qsSortingValue: string | string[] | undefined
) => {
  if (!qsSortingValue) return DEFAULT_SORT_BY;

  if (Array.isArray(qsSortingValue)) {
    return qsSortingValue.find(isSortCriterion) || DEFAULT_SORT_BY;
  }

  return isSortCriterion(qsSortingValue) ? qsSortingValue : DEFAULT_SORT_BY;
};

export const sortingOptions: Record<string, ListingsSortingOption> = {
  automatic: {
    label: 'lbl_automatic', // i18n-extract-keys ["lbl_automatic"],
    value: SORTING_VALUES.automatic,
    criterion: SORTING_CRITERION.automatic,
  },
  moreRelevant: {
    label: 'piu_rilevanti', // i18n-extract-keys ["piu_rilevanti"],
    value: SORTING_VALUES.moreRelevant,
    criterion: SORTING_CRITERION.relevance,
  },
  moreExpensive: {
    label: 'piu_costosi', // i18n-extract-keys ["piu_costosi"]
    value: SORTING_VALUES.moreExpensive,
    criterion: SORTING_CRITERION.price,
    direction: 'desc' as const,
  },
  bigger: {
    label: 'piu_grandi', // i18n-extract-keys ["piu_grandi"]
    value: SORTING_VALUES.larger,
    criterion: SORTING_CRITERION.surface,
    direction: 'desc' as const,
  },
  moreRooms: {
    label: 'piu_locali', // i18n-extract-keys ["piu_locali"]
    value: SORTING_VALUES.moreLocal,
    criterion: SORTING_CRITERION.rooms,
    direction: 'desc' as const,
  },
  newer: {
    label: 'piu_recenti', // i18n-extract-keys ["piu_recenti"]
    value: SORTING_VALUES.latest,
    criterion: isListingSearchNewSortingEnabled
      ? SORTING_CRITERION.creationDate
      : SORTING_CRITERION.modificationDate,
    direction: 'desc' as const,
  },
  lessExpensive: {
    label: 'meno_costosi', // i18n-extract-keys ["meno_costosi"]
    value: SORTING_VALUES.lessExpensive,
    criterion: SORTING_CRITERION.price,
    direction: 'asc' as const,
  },
  smaller: {
    label: 'meno_grandi', // i18n-extract-keys ["meno_grandi"]
    value: SORTING_VALUES.lessBig,
    criterion: SORTING_CRITERION.surface,
    direction: 'asc' as const,
  },
  lessRooms: {
    label: 'meno_locali', // i18n-extract-keys ["meno_locali"]
    value: SORTING_VALUES.lessLocal,
    criterion: SORTING_CRITERION.rooms,
    direction: 'asc' as const,
  },
  older: {
    label: 'meno_recenti', // i18n-extract-keys ["meno_recenti"]
    value: SORTING_VALUES.older,
    criterion: isListingSearchNewSortingEnabled
      ? SORTING_CRITERION.creationDate
      : SORTING_CRITERION.modificationDate,
    direction: 'asc' as const,
  },
  higherFloor: {
    label: 'lbl_sorting_higher_floor', // i18n-extract-keys ["lbl_sorting_higher_floor"]
    value: SORTING_VALUES.higherFloor,
    criterion: SORTING_CRITERION.floor,
    direction: 'desc' as const,
  },
  lowestFloor: {
    label: 'lbl_sorting_lowest_floor', // i18n-extract-keys ["lbl_sorting_lowest_floor"]
    value: SORTING_VALUES.lowestFloor,
    criterion: SORTING_CRITERION.floor,
    direction: 'asc' as const,
  },
  moreExpensiveMq: {
    label: 'lbl_sorting_more_expensive_mq', // i18n-extract-keys ["lbl_sorting_more_expensive_mq"]
    value: SORTING_VALUES.moreExpensiveMq,
    criterion: SORTING_CRITERION.priceMq,
    direction: 'desc' as const,
  },
  lessExpensiveMq: {
    label: 'lbl_sorting_less_expensive_mq', // i18n-extract-keys ["lbl_sorting_less_expensive_mq"]
    value: SORTING_VALUES.lessExpensiveMq,
    criterion: SORTING_CRITERION.priceMq,
    direction: 'asc' as const,
  },
  agencyAdvertisersFirst: {
    label: 'lbl_sorting_agencies', // i18n-extract-keys ["lbl_sorting_agencies"]
    value: SORTING_VALUES.agencyAdvertisersFirst,
    criterion: SORTING_CRITERION.agencyAdvertisersFirst,
  },
  privateAdvertisersFirst: {
    label: 'lbl_sorting_private_advertisers', // i18n-extract-keys ["lbl_sorting_private_advertisers"]
    value: SORTING_VALUES.privateAdvertisersFirst,
    criterion: SORTING_CRITERION.privateAdvertisersFirst,
  },
  discounted: {
    label: 'lbl_sorting_discounted', // i18n-extract-keys ["lbl_sorting_discounted"]
    value: SORTING_VALUES.discounted,
    criterion: SORTING_CRITERION.discounted,
  },
};

export const getSortingOptions = (
  options: (keyof typeof sortingOptions)[],
  trans: Trans
): ListingsSortingOption[] =>
  options.map((option) => ({
    ...sortingOptions[option],
    label: trans(sortingOptions[option].label), // i18n-extract-disable-line
  }));

export const getDefaultOptionValue = (
  sortingOptions: Record<string, ListingsSortingOption>
) =>
  isListingSearchNewSortingEnabled
    ? sortingOptions.moreRelevant.value
    : sortingOptions.automatic.value;

export const getSortingValue = (
  sortingCriterion: SortingCriterion | undefined,
  sortingDirection: SortingDirection | undefined
) => {
  const sortingOption = Object.values(sortingOptions).find(
    (option) =>
      option.criterion === sortingCriterion &&
      option.direction === sortingDirection
  );

  return sortingOption
    ? sortingOption.value
    : getDefaultOptionValue(sortingOptions);
};
