import { useGrowthBook } from '@growthbook/growthbook-react';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { tempSuffix } from '../constants';

import type { GrowthBookFeatures } from '../types';

/**
 * A workaround to use typed feature values with GrowthBook.
 *
 * As of `@growthbook/growthbook-react` v1.0.0', `useFeatureValue` doesn't support typed feature values.
 *
 * @param featureKey - The key of the feature flag in the GrowthBook dashboard. This key should be added to the `GrowthBookFeatures` interface.
 * @param defaultValue - The default value to return if the feature flag is not found or GrowthBook is disabled.
 */
export const useGrowthBookFeature = <K extends keyof GrowthBookFeatures>(
  featureKey: K,
  defaultValue: GrowthBookFeatures[K]
) => {
  const growthbook = useGrowthBook<GrowthBookFeatures>();

  const key = isFeatureEnabled('GROWTHBOOK_PROXY_TEST')
    ? (`${featureKey}${tempSuffix}` as keyof GrowthBookFeatures)
    : featureKey;

  return isFeatureEnabled('GROWTHBOOK')
    ? growthbook.getFeatureValue(key, defaultValue)
    : defaultValue;
};
