import { useEffect, useMemo } from 'react';
import { AuthModal } from '@indomita-react/auth-modal';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useAtomValue } from 'jotai';

import type { AuthAction, AuthServiceProvider } from './atoms/authModalAtom';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';
import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

export function AuthModalDialog() {
  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');
  const authModal = useAtomValue(authModalAtom);
  let token: string | undefined;

  useEffect(() => {
    token = document.querySelector<HTMLMetaElement>("meta[name='csrf-token']")
      ?.content;
  }, []);
  const initialData = useMemo(() => {
    if (authModal.data) {
      return {
        login: {
          email: {
            value: authModal.data.email || null,
            disabled: Boolean(authModal.data.email),
          },
        },
      };
    }
  }, [authModal.data]);
  const { setIsAuthenticated } = useAuthContext();

  const handleSuccess = (action: AuthAction, service?: AuthServiceProvider) => {
    if (isFeatureEnabled('ENABLE_UNIQUE_AUTH_MODAL')) {
      if (
        ['apple', 'google', 'facebook'].some(
          (social) => service?.includes(social)
        ) ||
        action === 'login' ||
        action === 'signup'
      ) {
        setIsAuthenticated(true);
      }

      authModal.onSuccess && authModal.onSuccess(action, service);
    } else {
      setIsAuthenticated(true);
      authModal.onSuccess && authModal.onSuccess(action, service);
    }
  };

  return authModal.open && authModal.onClose ? (
    <AuthModal
      contentType={
        authModal.contentType ? authModal.contentType : 'authFormLogin'
      }
      token={
        isFeatureEnabled('ENABLE_UNIQUE_AUTH_MODAL') &&
        authModal.contentType === 'default'
          ? token
          : undefined
      }
      advantageInfo={authModal.showAdvantageInfo}
      domainName={domainName}
      initialData={initialData}
      onSuccess={(action, response) => {
        handleSuccess(action, response?.service);
      }}
      onClose={authModal.onClose}
      alertMessage={authModal.data?.alertMessage}
      alertMessageType={authModal.data?.alertMessageType}
      additionalTrackingData={authModal.data?.additionalTrackingData}
      buttonText={authModal.buttonText}
      agencySignUp={authModal.agencySignUp}
      phoneCountryCode={getProductConfig('phoneCountryCode')}
    />
  ) : null;
}
