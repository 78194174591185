export enum GATrackingEvent {
  LISTING_VIEWED = 'listing_viewed',
  LISTING_SAVED = 'listing_saved',
  LISTING_HIDDEN = 'listing_hidden',
  LISTING_SHARED = 'listing_shared',
  LISTING_SAVED_REMOVED = 'listing_saved_removed',
  LISTING_HIDDEN_REMOVED = 'listing_hidden_removed',
  VIEW_ITEM = 'view_item',
  SAVE_NOTE = 'save_note',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  MORTGAGE_CLICK = 'mortgage_click',
  HOMEPAGE_LOAD = 'homepage_load',
  SAVE_SEARCH_CONFIRMED = 'save_search_confirmed',
  SAVE_SEARCH_UPDATED = 'save_search_updated',
  SAVE_SEARCH_IMPRESSION = 'save_search_impression',
  LISTING_DETAIL_PREVIEW = 'listing-detail-preview',
  LIST_LOAD = 'list_load',
  VIEW_ITEM_LIST = 'view_item_list',
  AGENCY_CARD_LOAD = 'agencycard_load',
  AGENT_CARD_LOAD = 'agentcard_load',
  PURCHASE = 'purchase',
  CONTACT = 'contact',
  USER_LOGIN = 'user_login',
  USER_SIGNIN = 'user_subscription',
  BANNER_APP = 'banner_app',
  EUROANSA_CONTACT = 'euroansa_contact',
  LISTING_PREVIEW = 'listing_preview',
  LISTING_PREVIEW_CAROUSEL = 'listing_preview_carousel',
  AGENCY_LIST_LOAD = 'agencylist_load',
  BOOKING = 'booking',
  AGENCY_PREVIEW_CAROUSEL = 'agency_preview_carousel',
  AGENCY_PREVIEW = 'agency_preview',
  BANNER_TOP_CLICK = 'banner_top_click',
}

export enum SectionName {
  LISTING_DETAIL = 'dettaglio_annuncio',
  SEARCH_LIST = 'lista_annunci',
  SEARCH_MAP = 'mappa_annunci',
  SEARCH_MAP_SAVED = 'mappa_annunci_salvati',
  HOMEPAGE = 'homepage',
  PROPERTY_PRICES = 'mercato_immobiliare',
  AGENCY_CARD = 'scheda_agenzia',
  FRANCHISING_CARD = 'scheda_gruppo',
  AGENT_CARD = 'scheda_agente',
  EUROANSA = 'euroansa',
  LANDING_LOGIN = 'landing_login',
  LANDING_SUBSCRIPTION = 'landing_subscription',
  AGENCY_LIST = 'lista_agenzie',
  ASSOCIATION_CARD = 'scheda_associazione',
  LISTINGS_SAVED = 'lista_annunci_salvati',
}

export enum SectionNameOldGTM {
  LISTING_DETAIL = 'listing_detail',
  SEARCH_MAP = 'search_map',
  SEARCH_LIST = 'search_list',
  SEARCH_MAP_SAVED = 'search_map_saved',
}

export enum PurchaseAndContactEventValue {
  PHONE = 1,
  EMAIL = 2,
  VISIT = 5,
}

export enum ContactCategory {
  AGENCY = 'agenzia',
  REAL_ESTATE = 'immobile',
}

export enum ContactSubType {
  REMOTE = 'a distanza',
  ON_SITE = 'di persona',
  SMART = 'smart',
  NO_SMART = 'no smart',
}

export enum GARDEN_VALUE {
  SHARED = 'Giardino comune',
  PRIVATE = 'Giardino privato',
}

export enum FLOOR_VALUE {
  GROUND = 'Piano Terra',
  INTERMEDIATE = 'Intermedio',
  TOP = 'Ultimo',
}

export enum CONDITION_VALUE {
  GOOD_OR_LIVABLE = 'Buono/Abitabile',
  RENOVATE = 'Da ristrutturare',
  NEW_OR_UNDER_CONSTRUCTION = 'Nuovo/In costruzione',
  BEST_OR_RENOVATED = 'Ottimo/Ristrutturato',
}

export enum GARAGE_VALUE {
  SINGLE = 'Garage singolo',
  DOUBLE = 'Garage doppio',
  PARKING_SPACE = 'Posto auto',
}

export enum HEATING_VALUE {
  AUTONOMOUS = 'Riscaldamento autonomo',
  AIR_CONDITIONING = 'Aria condizionata',
  CENTRALIZED = 'Riscaldamento centralizzato',
}

export enum OTHER_FEATURES_SEARCH_VALUE {
  FURNISHED = 'Arredato',
  BASEMENT = 'Cantina',
  POOL = 'Piscina',
  ELEVATOR = 'Ascensore',
  ALARM_SYSTEM = 'Sistema di allarme - vigilanza',
  SMOKERS = 'Fumatori graditi',
  ANIMALS = 'Animali graditi',
}

export enum TYPOLOGIES_VALUE {
  APPARTAMENTO = 'Appartamento',
  ATTICO_MANSARDA = 'Attico - Mansarda',
  CASA_INDIPENDENTE = 'Casa indipendente',
  LOFT = 'Loft',
  RUSTICO_CASALE = 'Rustico - Casale',
  VILLA = 'Villa',
  VILLETTA_A_SCHIERA = 'Villetta a schiera',
  UFFICIO = 'Ufficio',
  NEGOZIO = 'Negozio',
  MAGAZZINO = 'Magazzino',
  CAPANNONI = 'Capannoni',
  NEGOZI_LOCALI_COMMERCIALI = 'Locale commerciale',
  LABORATORIO = 'Laboratorio',
  ATTIVITA_COMMERCIALE = 'Attività commerciale',
  TERRENO_AGRICOLO = 'Terreno agricolo',
  TERRENO_EDIFICABILE = 'Terreno edificabile',
  POSTO_LETTO = 'Posto letto',
  COMPLETA = 'Stanza completa',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  LOFT_OPEN_SPACE = 'Loft',
}

export enum CATEGORIES_VALUE {
  RESIDENTIAL = 'Case - Appartamenti',
  ROOMS = 'Stanze - Posti letto',
  NEW_CONSTRUCTIONS = 'Nuove costruzioni',
  BOX_GARAGE = 'Garage - Posti auto',
  PALACE = 'Palazzi - Edifici',
  COWORKING = 'Uffici - Coworking',
  SHOPS = 'Negozi - Locali commerciali',
  DEPOSITS = 'Magazzini - Depositi',
  SHEDS = 'Capannoni',
  LANDS = 'Terreni',
}

export enum COOKIE_SOCIAL {
  SIGNUP = 'signup_social',
  USER_ID = 'user_uuid',
}

export enum AppBannerAction {
  CLOSE = 'close',
  DOWNLOAD = 'download',
  VIEW = 'view',
  IMPRESSION = 'impression',
  SCARICA = 'scarica',
  CHIUDI = 'chiudi',
}

export enum EventName {
  NOTE_SAVED = 'Note Saved',
  LISTING_VIEWED = 'Listing Viewed',
  LISTING_VIEWED_NEW_CONSTRUCTION = 'Listing Viewed within New Construction',
  LISTING_SAVED = 'Listing Saved',
  LISTING_REMOVED = 'Listing Removed',
  LISTING_HIDDEN = 'Listing Hidden',
  MESSAGE_CONTACT_REQUEST = 'Message Contact Request',
  MORTGAGE_LANDING_ACCESS = 'Mortgage Landing Access',
  SEARCH_SAVED = 'Search Saved',
  SEARCH_FILTER = 'Search Filter',
  LOGIN_COMPLETE = 'Log In Complete',
  LOGIN_FAILED = 'Log In Failed',
  LOGIN_START = 'Log In Start',
  MAP_LISTINGS_SHOWN = 'Map Listings Shown',
  BANNER_SELECTED = 'N.1 Banner selected',
  LISTINGS_SHOWN = 'Listings Shown',
  SEARCH_FROM_HOME = 'Search From Home',
  MAP_LIST_MODE_SELECTED = 'Map/List Mode Selected',
  MAP_PIN_SELECTED = 'Map Pin Selected',
  FEEDBACK_LISTING_DETAIL_ISSUE = 'Feedback Listing-Detail-issue',
  STRONG_USER_CONVERSION = 'Strong User Conversion',
  LIGHT_USER_REGISTRATION = 'Light User Registration',
  PAGE_CATEGORY_SECTION = 'Page Category Section',
  PHONE_CONTACT_REQUEST = 'Phone Contact Request',
  MESSAGING_MODAL_SUBMIT = 'Messaging Modal Submit',
  WEB_SITE_CLICKED = 'Web Site Clicked',
  AGENCY_LOCATION_BUTTON_PRESSED = 'Agency Location Button Pressed',
  AGENCY_BOX_SECTION_SELECTED = 'Agency Box Section Selected',
  AGENCY_CONTACT_REQUEST = 'Agency contact request',
  CAROUSEL_LIST_CHANGED = 'Carousel Listing Changed',
  LIGHT_LISTING_VIEWED = 'Light Listing Viewed',
  VISIT_REQUEST_MODAL_SUBMIT = 'Visit Request - Modal submit',
  AGENCY_SEARCH_LAUNCHED = 'Agency Search Launched',
  AGENCY_VIEWED = 'Agency Viewed',
  AGENCY_GROUP_SHOWN = 'Agency Group Shown',
  DRAW_MAP_SHORTCUT_PRESSED = 'Draw Map Shortcut Pressed',
  ISOCHRNOUS_MAP_SHORTCUT_PRESSED = 'Isochronous Map Shortcut Pressed',
  ZONES_MAP_SHORTCUT_PRESSED = 'Zones Map Shortcut Pressed',
  LAUNCH_SEARCH = 'Launch Search',
  BOOKING_BUTTON_PRESSED = 'Booking Button Pressed',
  TROVAKASA_FORM_SENT = 'Trovakasa Form Sent',
  MULTI_CONTACT_BUTTON_PRESSED = 'Multicontact Button Pressed',
  MULTI_CONTACT_BUTTON_SHOWN = 'Multicontact Modal Shown',
  CAROUSEL_AGENCY_CHANGED = 'Carousel Agency Changed',
  AGENCY_MAP_PIN_PRESSED = 'Agency Map Pin Pressed',
  SEARCH_HERE_PRESSED = 'Search in this area pressed',
  SEARCH_HERE_CONFIRMED = 'Search in this area confirmed',
  VIEW_SAVED_SEARCHES_LIST = 'View Saved Searches List',
  VIEW_SAVED_LISTINGS_LIST = 'View Saved Listings List',
  LISTING_CONTACT_REQUEST = 'Listing Contact Request',
  SEARCH_UPDATED = 'Search Updated',
  SEARCH_DELETED = 'Search Deleted',
  FEATURED_ARTICLE_CLICKED = 'Featured article clicked',
  ASSOCIATED_SERVICE_CLICKED = 'Associated service clicked',
  SEARCHBAR_ZONES_BUTTON_PRESSED = 'Searchbar Zones Button Pressed',
  ACTIVE_SEARCH_ENABLED = 'Active Search Enabled',
  ACTIVE_SEARCH_DISABLED = 'Active Search Disabled',
  AGENT_PAGE_VIEWED = 'Agent Page Viewed',
  AGENCY_SHOWN = 'Agency Shown',
  SHORTCUT_SEARCH_LAUNCHED = 'Shortcut Search Launched',
  PLANS_OPENED = 'Plans Opened',
  FOTOPLAN_OPENED = 'Fotoplan Opened',
  VIRTUAL_TOUR_OPENED = 'Virtual Tour Opened',
  VIDEO_OPENED = 'Video Opened',
  EXPAND_DESCRIPTION_PRESSED = 'Expand Description Pressed',
  LISTING_MAP_OPENED = 'Listing Map Opened',
  LISTING_SHARED = 'Listing Shared',
  SURFACE_DETAILS_EXPANDED = 'Surface Details Expanded',
  PHOTO_OPENED = 'Photo Opened',
  LISTING_SECTIONS_VIEWED = 'Listing Sections Viewed',
  VISIT_REQUEST_STEP_ONE = 'Visit Request - Step 1',
  VISIT_REQUEST_STEP_TWO = 'Visit Request - Step 2',
  DETAIL_PHOTO_OPENED = 'Detail Photo Opened',
  ENERGETIC_EFFICIENCY_EXPANDED = 'Energetic Efficiency Expanded',
  SEARCH_SAVE_MODAL_OPENED = 'Search Save Modal Opened',
  SEARCH_UPDATE_MODAL_OPENED = 'Search Update Modal Opened',
  SEARCH_EDIT_MODAL_OPENED = 'Search Edit Modal Opened',
  ALL_FILTERS_MODAL_OPENED = 'All Filters Modal Opened',
  MAIN_DATA_EXPANDEND = 'Main Data Expanded',
  BANNER_TOP_CLICKED = 'Banner Top Clicked',
  STICKY_CHIP_CLICKED = 'Sticky Chip Clicked',
  AVAILABLE_FILTER_SELECTED = 'Available Filter Selected',
  PROPOSE_PRICE_MODAL_SUBMIT = 'Propose Price - Modal submit',
  PROPOSE_PRICE_STEP_ONE = 'Propose Price - Step 1',
  PROPOSE_PRICE_STEP_TWO = 'Propose Price - Step 2',
}

export enum TrackingReducerAction {
  SET_REAL_ESTATE_TRACKING_DATA = 'SET_REAL_ESTATE_TRACKING_DATA',
  SET_COMMON_TRACKING_DATA = 'SET_COMMON_TRACKING_DATA',
}

export enum TrackListingsShownEvent {
  LISTINGS_SHOWN = 'listings-shown',
  MAP_LISTING_SHOWN = 'map-listings-shown',
}

export enum EntryPoint {
  HOME = 'Home',
  SAVED_LISTINGS = 'Annunci Salvati',
  SAVED_SEARCHES = 'Ricerche Salvate',
  RELATED_LISTINGS = 'Annunci Simili',
  SELL_BUILDING = 'Vendi Immobile',
  EVALUATE_BUILDING = 'Valuta Immobile',
  EVALUATE_REQUEST = 'Richieste di Valutazione',
  PUBLISH_AD = 'Pubblica Annuncio',
  PUBLISHED_ADS = 'Annunci Pubblicati',
  MESSAGES = 'Messaggistica',
  HIDDEN_ADS = 'Annunci nascosti',
  LAST_ADS = 'Annunci Recenti',
  LAST_RESEARCH = 'Ricerche Recenti',
  EMAIL = 'Email',
  PUSH = 'Push',
  ABROAD = 'Estero',
  AGENCY = 'Agenzie',
  BUILDING_PRICES = 'Prezzi immobili',
  MORTGAGE = 'Mutui',
  LIST = 'Lista',
  MAP = 'Mappa',
  DETAIL = 'Dettaglio',
  AGENCY_DETAIL = 'Dettaglio Agenzia',
  FRANCHISING_DETAIL = 'Gruppo Agenzia',
  AGENT_DETAIL = 'Pagina Agente',
  AGENCY_LIST = 'Lista Agenzie',
  LOGIN_PAGE = 'Login Page',
  NA = 'N/A',
  ASSOCIATION_PAGE = 'Pagina Associazione',
  CARD_ANNUNCIO = 'Card Annuncio',
  BANNER_DETAIL = 'Dettaglio - Banner',
  NAVIGATION_DETAIL = 'Dettaglio - Navigation',
  GALLERY_DETAIL = 'Dettaglio - Gallery',
  SAVE_SEARCH_MODAL = 'Modale Salva Ricerca',
  EDIT_SEARCH_MODAL = 'Modale Modifica Ricerca',
  UPDATE_SEARCH_MODAL = 'Modale Aggiorna Ricerca',
  GALLERY_LISTING_DETAIL = 'Gallery dettaglio annuncio',
  DETAIL_PRICE_INFORMATION = 'Dettaglio - Informazioni sul prezzo',
}

export enum TrackHandleContactsClickType {
  TELEPHONE = 'telephone',
  MESSAGE = 'message',
}

export enum EmailAndPhoneStorageKey {
  LAST_USED_PHONE = 'lastUsedPhone',
  LAST_USED_EMAIL = 'lastUsedEmail',
}

export const REVENUES = {
  PHONE: 0.5,
  MESSAGE: 0.75,
  IMMOVISITA: 2,
  F2F_VISIT: 5,
} as const;

export enum CONTACT_TYPES {
  PHONE = 'Phone',
  MESSAGE = 'Message',
  VIRTUAL_VISIT = 'Virtual Visit',
  F2F_VISIT = 'f2f Visit',
  UNDEFINED = 'undefined',
}

export const RealEstatePositionInfo = 'REAL_ESTATE_POSITION_INFO';
