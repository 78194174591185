import { useEffect, useRef } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import {
  addSegmentIntegrations,
  getCommonTrackingData,
  segmentAnalyticsIdentify,
  segmentAnalyticsLoad,
  SegmentProvider,
  setCommonTrackingData,
} from '@indomita-react/segment';
import { useRouter } from 'next/router';

import {
  useOnConsentsChanged,
  useOnDidomiLoaded,
} from 'src/components/CookieManager/CookieProvider';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { getPartnersChoicesSelection } from './utils';

interface SegmentAnalyticsProps {
  children: React.ReactNode;
}

export function SegmentAnalyticsProvider({ children }: SegmentAnalyticsProps) {
  // Lazy load analytics only after didomi is loaded all the previously tracked events will be sent after the load
  const segmentConfig = getProductConfig('segmentConfig');
  const isEnabled = isFeatureEnabled('SEGMENT_TRACKING');
  const isFirstRender = useRef(true);
  const isUserJustLogged = useRef(false);

  const { user } = useAuthContext();

  useEffect(() => {
    // if is first render we don't want to send the identify event
    // because we do not know if the user just logged in or not
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    // if the isAuthenticated is true and is not the first render,
    // this means that the user just logged in; But probably the user data is not ready
    // because we have to wait until the getUserInfo request is completed
    // so we're going to set a ref to tue and we're going to send the identify event
    // when the user data is ready
    if (user && !isUserJustLogged.current) {
      isUserJustLogged.current = true;

      return;
    }

    // when the user data will change we're going to send the identify event
    // and then we're going to set the ref to false
    // in this way until the user will not log in again we're not going to send the identify event
    if (user && user.uuid && isUserJustLogged.current) {
      segmentAnalyticsIdentify(isEnabled, user.uuid);
      isUserJustLogged.current = false;
    }
  }, [user?.uuid, user, isEnabled]);

  const { locale } = useRouter();

  useOnDidomiLoaded(() => {
    const { 'Experimental Flags': existingFlags = [] } =
      getCommonTrackingData();

    setCommonTrackingData({
      'Experimental Flags': [...existingFlags],
    });

    segmentAnalyticsLoad(segmentConfig.apiHost, segmentConfig.apiKey, {
      enabled: isFeatureEnabled('SEGMENT_TRACKING'),
      batching: {
        enabled: isFeatureEnabled('SEGMENT_BATCHING'),
      },
    });
  });

  useOnConsentsChanged((vendors) => {
    addSegmentIntegrations(getPartnersChoicesSelection(vendors));
  });

  return (
    <SegmentProvider
      locale={locale ?? ''}
      isSegmentEnabled={isEnabled}
      userId={user?.uuid}
    >
      {children}
    </SegmentProvider>
  );
}
