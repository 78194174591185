import { useEffect } from 'react';
import { sendToDataLayerWebVitals } from '@pepita-fe/gtm';
import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';
import type {
  CLSMetric,
  FCPMetric,
  INPMetricWithAttribution,
  LCPMetric,
  TTFBMetric,
} from 'web-vitals/attribution';
import { onINP } from 'web-vitals/attribution';

const metricQueue = new Set<
  (CLSMetric | LCPMetric | FCPMetric | TTFBMetric) | INPMetricWithAttribution
>();

const addToQueue = (
  metric:
    | (CLSMetric | LCPMetric | FCPMetric | TTFBMetric)
    | INPMetricWithAttribution
) => {
  metricQueue.add(metric);
};

const flushQueue = () => {
  if (metricQueue.size > 0) {
    const metricsArray = [...metricQueue];

    for (const metric of metricsArray) {
      sendToDataLayerWebVitals(metric);
    }

    metricQueue.clear();
  }
};

export const useWebVitals = () => {
  useEffect(() => {
    onCLS(addToQueue);
    onLCP(addToQueue);
    onFCP(addToQueue);
    onTTFB(addToQueue);
    onINP(addToQueue);

    // Report all available metrics whenever the page is backgrounded or unloaded.
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        flushQueue();
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
