import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';
import Script from 'next/script';

const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

type DisplayedAdSlots = Record<string, googletag.Slot | undefined>;

type GPTContextValue = [
  DisplayedAdSlots,
  Dispatch<SetStateAction<DisplayedAdSlots>>,
];

const GPTContext = createContext<GPTContextValue | null>(null);

if (typeof window !== 'undefined') {
  window.googletag =
    (window.googletag as typeof window.googletag | undefined) ||
    ({ cmd: [] } as unknown as typeof googletag);

  // Prepare GPT to display ads.
  googletag.cmd.push(() => {
    // Disable initial load, to precisely control when ads are requested.
    googletag.pubads().disableInitialLoad();

    // Enable SRA and services.
    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
    googletag.setConfig({
      threadYield: 'DISABLED',
    });
  });
}

export function GPTProvider({ children }: PropsWithChildren) {
  const [displayedAdSlots, setDisplayedAdSlots] = useState<DisplayedAdSlots>(
    {}
  );

  return (
    <GPTContext.Provider value={[displayedAdSlots, setDisplayedAdSlots]}>
      <Script src={GPT_STANDARD_URL} async />
      {children}
    </GPTContext.Provider>
  );
}

export const useGPTContext = () => {
  const context = useContext(GPTContext);

  if (!context) {
    throw new Error('useGPTProvider must be used within a GPTProvider');
  }

  return context;
};
